/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-danger */
import React, { memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  errorDataField,
  updateDataField
} from '@actions/data-detail-actions';

import ScheduleWarning from '@components/entity/info/drawer/form/form-tab/schedule-warning';
import ParamsContext from '@components/entity/info/params-context';

import { getEntityTypeLabel, getOwnersAllowAllUsers } from '@constants/config';
import { detailEdit } from '@constants/mui-theme';

import DateRange from '@forms/date-range';
import FormElement from '@forms/form-element';
import InputDataTypeSearch from '@forms/input-datatype-search';
import SegmentList from '@forms/segment-list';

import Alert from '@material-ui/lab/Alert';

import { getMetadata, getVisibleFields } from '@selectors/forms-selector';

import { isFieldVisible } from '@utils/form-utils';
import { isReadOnly } from '@utils/permission-utils';

import '../../../../../forms/forms.scss';

const FormField = ({ fieldName, tab }) => {
  const dispatch = useDispatch();
  const { dataType } = useContext(ParamsContext);
  const { data, error: errors } = useSelector(state => state.dataDetail);
  const metadata = useSelector(state => getMetadata(dataType)(state));
  const { map_type: entityTypes } = useSelector(state => state.dataTypes);

  const dataTypeDisplayName = useMemo(() => getEntityTypeLabel(dataType), [dataType]);

  const onChange = (...args) => dispatch(updateDataField(...args));
  const onError = (...args) => dispatch(errorDataField(...args));

  const visibleFields = useSelector(state => getVisibleFields(dataType)(state));
  const readOnly = useMemo(() => isReadOnly(dataType), [dataType]);

  const isVisible = useMemo(() => isFieldVisible(fieldName, visibleFields), [fieldName, visibleFields]);

  if (!isVisible || !data) {
    return null;
  }

  switch (fieldName) {
  case '_date_warning':
    return <ScheduleWarning />;
  // Render a textarea with a configured text.
  case '_textarea':
    return (
      <div
        style={detailEdit.columnStyles.col100}
        styleName="col100 textarea"
        dangerouslySetInnerHTML={{__html: tab._textarea_data}}
      />
    );
  // Render an info message.
  case '_info_message':
    return (
      <div style={detailEdit.columnStyles.col100} styleName="col100">
        <Alert severity="info">
          <div dangerouslySetInnerHTML={{__html: tab._info_message}} />
        </Alert>
      </div>
    );
  case 'owners': {
    const { max_owners: maxOwners } = Object.values(entityTypes).find(type => type.name === dataType) || {};
    // Use a space ' ' as the label if there's data, to make the TextField component
    // think that there's a label, so it still reserves a 16px padding at the top.
    const label = data[fieldName] ? metadata[fieldName].defaultLabel || ' ' : 'Search users';

    let filterFunc = item => data.agency === item.agency;
    if (getOwnersAllowAllUsers()) {
      filterFunc = null;
    }

    return (
      <InputDataTypeSearch
        agency={data.agency}
        dataType="user"
        disabled={metadata[fieldName].read_only || readOnly}
        errors={errors.owners || null}
        fieldName={fieldName}
        filterFunc={filterFunc}
        onChange={(event, newValues) => {
          if (Array.isArray(newValues)) {
            const values = newValues.map(value => value.value);
            onChange(fieldName, values);
          } else {
            onChange(fieldName, [newValues.value]);
          }
        }}
        label={label}
        limit={maxOwners}
        values={data[fieldName] || []}
      />
    );
  }
  case 'segments':
    return (
      <div key={fieldName}>
        <SegmentList readOnly={metadata[fieldName].read_only || readOnly} />
      </div>
    );
  case '_date_range':
    return (
      <DateRange key={fieldName} fieldNames={tab.date_range_fields} readOnly={readOnly} />
    );
  default:
    return (
      <FormElement
        data={data}
        dataType={dataTypeDisplayName}
        errors={errors}
        key={fieldName}
        fieldMeta={metadata[fieldName]}
        fieldName={fieldName}
        onChange={onChange}
        onError={onError}
        readOnly={readOnly}
        value={data[fieldName]}
      />
    );
  }  // end switch
};

FormField.propTypes = {
  fieldName: PropTypes.string,
  tab: PropTypes.object
};

export default memo(FormField);
